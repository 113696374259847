<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/issues">To Do's</a>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
    }
  }
</script>
